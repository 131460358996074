var hardness = {
    g: {
      name: {
        singular: 'gram',
        plural: 'grams'
      },
      to_anchor: 9.80665 / 1000
    },
    N: {
      name: {
        singular: 'newton',
        plural: 'newtons'
      },
      to_anchor: 1
    },
    'shore-A': {
      name: {
        singular: 'shore A',
        plural: 'shore As'
      },
      to_anchor: 0.075
    },
    'shore-D': {
      name: {
        singular: 'shore D',
        plural: 'shore Ds'
      },
      to_anchor: 0.4445
    }
  };
  
  module.exports = {
    metric: hardness,
    _anchors: {
      hardness: {
        unit: 'N',
        ratio: 1
      }
    }
  };
  