import moment from "moment";
import { BASE_URL, MAX_COLS } from "../../../global";
import { toast } from "react-toastify";
import {
  prepareCertificateReadings,
  prepareTypeBValues,
  withUnit,
} from "./utils";
import { clearPreviousReadings, getCustomColumnCount } from "../helper";
import generateAudit from "../../audit/audit";
import { updateSRFAnalytics } from "../../../utils/analytics/srfAnalytics";
import { processDatasheet } from "./ObservedReadings/helper";
import { onSubmitOpinionAndAnalysis } from "./opinionAndAnalysisTable";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import { fetchData } from "../../uncertainties/process";

export const processDatasheetReadings = async (
  datasheetContextValue,
  _staticReadingRows = null
) => {
  // 0. extract the context

  const {
    state: {
      staticTables,
      staticReadingRows,
      certificateStaticTables,
      datasheetDetails,
      standardRanges,
      referenceData,
      settings,
    },
  } = datasheetContextValue;
  // 1. process data before submit

  let latestStaticReadingRows = _staticReadingRows
    ? _staticReadingRows
    : staticReadingRows;

  let updatedStaticReadingRows = await processDatasheet(
    staticTables,
    latestStaticReadingRows,
    settings?.["datasheetObservedReadingFallbackValue"],
    certificateStaticTables,
    datasheetDetails,
    standardRanges,
    referenceData
  );
  if (!updatedStaticReadingRows) {
    alert("Failed while processing datasheet data, please try again.");
    return null;
  }
  return updatedStaticReadingRows;
};

const getMaxMinRangeColValue = (tableId, staticTable, readingRows) => {
  let defaultConfig = JSON.parse(staticTable["defaultConfiguration"] || "{}");
  let rangeCol = Number(defaultConfig["rangeCol"]?.split("c")[1]) || -1;
  if (rangeCol === -1) return 0;

  let maxValue = readingRows[0]?.[rangeCol + 1] || 0;
  let minValue = maxValue;
  readingRows.forEach((row) => {
    if (row[0] === tableId) {
      let value = row[rangeCol + 1];
      if (value && value !== "NA") {
        value = value.replaceAll("$", "");
        value = Number(value);
        if (isNaN(value)) return;

        if (maxValue === null) maxValue = value;
        if (minValue === null) minValue = value;

        if (value > maxValue) maxValue = value;
        if (value < minValue) minValue = value;
      }
    }
  });
  return [maxValue, minValue];
};

const getAllReadingRangeValues = (tableId, staticTable, readingRows) => {
  let defaultConfig = JSON.parse(staticTable["defaultConfiguration"] || "{}");
  let rangeCol = Number(defaultConfig["rangeCol"]?.split("c")[1]) || -1;
  if (rangeCol === -1) return 0;

  let values = [];
  readingRows.forEach((row) => {
    if (row[0] === tableId) {
      let value = row[rangeCol + 1];
      if (value && value !== "NA") {
        value = value.replaceAll("$", "");
        value = Number(value);
        if (isNaN(value)) return;
        values.push(value);
      }
    }
  });
  values = values.join(",") || "";
  return values;
};

const checkSupportiveMaster = (updatedStaticReadingRows, selectedSupportiveRanges, supportiveStandardMasterArray) => {
  let isSupportiveMasterMissing = false;
  if(supportiveStandardMasterArray.length > 0){
    for (let idx = 0; idx < updatedStaticReadingRows.length; idx++) {
      const updatedReadingRow = updatedStaticReadingRows[idx];
      const supportiveRanges = selectedSupportiveRanges[idx] ?? null;
  
      // Check conditions for notselect supportiverange
      if ((supportiveRanges === null || supportiveRanges === "") &&
          !updatedReadingRow[2]?.includes("_unit_") &&
          !updatedReadingRow[2]?.includes("_rh_")) {
            isSupportiveMasterMissing = true;
      }
    }
  }

  if (isSupportiveMasterMissing && !window.confirm("Supportive Master is/are not added for rows? Do you want to save?")) {
    return false;
  }
  return true;
};

export const validateAndSubmitData = async (
  datasheetContextValue,
  showAmendmentModal,
  additionalAttr = {}
) => {
  // 0. extract the context
  const {
    state: {
      datasheetDetails,
      certificateDetails,
      staticTables,
      datasheetStaticReadings,
      staticReadingRows,
      certificateStaticTables,
      certificateStaticReadings,
      selectedStandardIds,
      selectedSupportiveRanges,
      indicators,
      analysisSelectedOption,
      settings,
      instrumentDetails,
      DatasheetReadingsAdditionalInfo,
      srfDetails,
      supportiveStandardMasterArray
    },
  } = datasheetContextValue;

  let stepsCompleted = 0;

  // 1. validate the data
  if (
    !datasheetDetails?.calibrationDate ||
    !datasheetDetails?.startHumidity ||
    !datasheetDetails?.startTemp ||
    Object.keys(indicators.outOffRangeTracker).length ||
    indicators?.invalidLCPrecision
    // TODO: uncomment this when precision validator when issue is fixed
    // || Object.keys(precNotEqTracker).length
  ) {
    let alerts = [];
    if (!datasheetDetails?.calibrationDate)
      alerts.push("Please add the calibration date!");
    if (!datasheetDetails?.startHumidity)
      alerts.push("Please add the startHumidity!");
    if (!datasheetDetails?.startTemp) alerts.push("Please add the startTemp!");

    if (indicators?.invalidLCPrecision) {
      alerts.push(
        "selected master LC precision count is lower than DUC master's LC !"
      );
    }
    if (Object.keys(indicators.outOffRangeTracker).length)
      alerts.push(
        "Readings can't be greater than 'DUC Higher Range' and 'Master To Range' !"
      );
    // TODO: uncomment this when precision validator issue is fixed
    // if (Object.keys(precNotEqTracker).length)
    //   alerts.push(
    //     "Please make sure all reading should have correct precisions!"
    //   );
    alert(alerts.join("\n"));
    return { status: false, stepsCompleted: stepsCompleted };
  }
  
  if (!checkSupportiveMaster(staticReadingRows, selectedSupportiveRanges, supportiveStandardMasterArray)) {
    return  {status:false, stepsCompleted: stepsCompleted};
  }
    
  stepsCompleted=1;

  // 2. take amendmend if required
  if (
    indicators?.isShowAmendment &&
    datasheetDetails?.revisionNumber > -1 &&
    !datasheetDetails?.reasonForAmendment
  ) {
    showAmendmentModal();
    return { status: false, stepsCompleted: stepsCompleted };
  }
  stepsCompleted = 2;

  try {
    let res = null;
    // 3 submit datasheet details
    console.log("submitting datasheet details");
    res = await submitDatasheet(
      datasheetDetails?.id,
      datasheetDetails,
      staticReadingRows,
      selectedStandardIds,
      indicators,
      instrumentDetails
    );
    if (!res) {
      alert("Failed while submitting datasheet details, please try again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 3;

    // 4 submit certificate details
    console.log("submitting certificate details");
    res = await submitCertificate(
      datasheetDetails?.id,
      datasheetDetails,
      certificateDetails,
      selectedStandardIds,
      indicators,
      instrumentDetails
    );
    if (!res) {
      alert("Failed while submitting certificate details, please try again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 4;

    // 5 submit srf instrument details
    console.log("submitting srf instrument details");
    res = await submitSrfIntrument(datasheetDetails?.id, datasheetDetails);
    if (!res) {
      alert(
        "Failed while submitting srf instrument details, please try again."
      );
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 5;

    // 6 submit srf details
    console.log("submitting srf details");
    res = await submitSRF(datasheetDetails, srfDetails);
    if (!res) {
      alert("Failed while submitting srf details, please try again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 6;

    // 7 submit datasheet readings
    console.log("submitting datasheet readings");
    let [_res, readings] = await submitDatasheetReadings(
      datasheetDetails?.id,
      datasheetDetails,
      datasheetStaticReadings,
      staticReadingRows,
      staticTables,
      selectedSupportiveRanges
    );
    if (!_res) {
      alert("Failed while submitting datasheet readings, please try again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 7;

    // 8 submit certificate readings
    console.log("submitting certificate readings");
    res = await submitCertificateReadings(
      datasheetDetails,
      certificateDetails,
      certificateStaticTables,
      certificateStaticReadings,
      staticReadingRows,
      readings,
      staticTables,
      indicators.rowWiseComplianceStatus
    );
    if (!res) {
      alert("Failed while submitting certificate readings, please try again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 8;

    // 9 calculate uncertainty
    if (additionalAttr?.isSubmitUncertainty) {
      console.log("Calculating uncertainty values");
      let uncertainties = {};
      try {
        for (let i = 0; i < staticTables.length; i++) {
          if (!staticTables[i].isUncertainty) continue;

          let values = getMaxMinRangeColValue(
            staticTables[i].id,
            staticTables[i],
            staticReadingRows
          );
          let maxReadingRangeValue = values[0];
          let minReadingRangeValue = values[1];

          let allReadingRangeVales = getAllReadingRangeValues(
            staticTables[i].id,
            staticTables[i],
            staticReadingRows
          );
          res = await fetchData(
            null,
            staticTables[i].id,
            datasheetDetails?.id,
            datasheetDetails?.instrumentId,
            maxReadingRangeValue,
            minReadingRangeValue,
            allReadingRangeVales,
            null,
            "origin:fromDatasheet"
          );
          // TODO: remove if not used anymore
          uncertainties = { ...uncertainties, ...res };
        }
        console.log("uncertainty values calculated successfully");
      } catch (error) {
        console.error(
          "Failed while calculating uncertainty values, error: ",
          error
        );
        return { status: false, stepsCompleted: stepsCompleted };
      }
    }
    stepsCompleted = 9;

    // 10 submit AdditionalInfo of Datasheet readings
    res = await submitDatasheetReadingsAdditionalInfo(
      DatasheetReadingsAdditionalInfo,
      datasheetDetails?.id
    );
    if (!res) {
      alert(
        "Failed while submitting AdditionalInfo of Datasheet readings, please try again."
      );
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 10;

    // 11 Submit OpinionAndAnalysisTable
    if (settings?.["Opinion And Analysis Table"] === "true") {
      console.log("Submitting Opinion And Analysis Data");
      res = await onSubmitOpinionAndAnalysis(
        analysisSelectedOption,
        datasheetDetails?.id
      );

      if (!res) {
        alert(
          "Failed while submitting opinion and analysis data, please try again."
        );
        return { status: false, stepsCompleted: stepsCompleted };
      }
    }
    stepsCompleted = 11;
  } catch (error) {
    console.error(
      "[ERROR] Error occured while submitting datasheet data, error: ",
      error
    );
    return { status: false, stepsCompleted: stepsCompleted };
  }

  if (additionalAttr?.origin !== "process") {
    toast.success("Datasheet updated successfully.");
  }
  console.log("datasheet updated successfully.");
  return { status: true, stepsCompleted: stepsCompleted };
};

export const submitDatasheet = async (
  datasheetId,
  datasheetDetails,
  updatedStaticReadingRows,
  selectedStandardIds,
  indicators,
  instrumentDetails
) => {
  try {
    const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
    let designation = localStorage.getItem("designation") || "";
    let data = {
      calibrationDate: datasheetDetails?.calibrationDate
        ? moment(datasheetDetails?.calibrationDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : null,
      nextDueDate: datasheetDetails?.nextDueDate
        ? moment(datasheetDetails?.nextDueDate).format("YYYY-MM-DD")
        : null,
      receiptDate: datasheetDetails?.receiptDate
        ? moment(datasheetDetails?.receiptDate).format("YYYY-MM-DD")
        : null,
      DCDate: datasheetDetails?.dcDate
        ? moment(datasheetDetails?.dcDate).format("YYYY-MM-DD")
        : null,
      dateOfIssue: datasheetDetails?.dateOfIssue
        ? moment(datasheetDetails?.dateOfIssue).format("YYYY-MM-DD")
        : null,
      identificationNo: datasheetDetails?.identificationNo,
      make: datasheetDetails?.make,
      ranges: datasheetDetails?.ranges,
      lc: datasheetDetails?.lc,
      DUCID: datasheetDetails?.DUCID,
      mfgNo: datasheetDetails?.mfgNo,
      startTemp: datasheetDetails?.startTemp,
      endTemp: datasheetDetails?.endTemp,
      startHumidity: datasheetDetails?.startHumidity,
      endHumidity: datasheetDetails?.endHumidity,
      configuration: JSON.stringify({
        ...datasheetDetails?.configuration,
        remark:
          datasheetDetails?.configuration?.remark ||
          datasheetDetails?.defaultReamrk ||
          instrumentDetails?.instrumentRemark,
      }),
      totalReadings: updatedStaticReadingRows?.length || 0,
      tableDisplayStatus: datasheetDetails.tableDisplayStatus,
      calibratedby: user[0],
      designation: designation,
      revisionNumber: Number(datasheetDetails?.revisionNumber) + 1,
      requestedname: datasheetDetails?.requestedname,
      additionalStandardIds: selectedStandardIds?.join(","),
      calibrationReason: datasheetDetails?.calibrationReason,
      complianceStatus: indicators?.complianceStatus,
      calProcRefNo: datasheetDetails?.calProcRefNo,
      isaStandard: datasheetDetails?.isaStandard,
      amendment:
        indicators?.isShowAmendment && datasheetDetails?.revisionNumber > -1
          ? datasheetDetails?.amendmentHistory?.length
            ? datasheetDetails?.amendmentHistory +
              "\n" +
              datasheetDetails?.reasonForAmendment +
              "," +
              moment().format("DD/MM/YYYY")
            : datasheetDetails?.reasonForAmendment +
              "," +
              moment().format("DD/MM/YYYY")
          : "",
      atmosphericPressure: datasheetDetails?.atmosphericPressure,
      extraColumns: (() => {
        let tmp = {};
        datasheetDetails?.extraColumns.forEach((e) => (tmp[e[0]] = e[1]));
        return JSON.stringify(tmp);
      })(),
    };

    let res = await axiosWithToken.patch(
      BASE_URL + `datasheets/${datasheetId}`,
      data
    );
    return res ? true : false;
  } catch (error) {
    console.error(
      `[ERROR] Failed to submit datasheet details, error: ${error}`
    );
    return false;
  }
};

export const submitCertificate = async (
  datasheetId,
  datasheetDetails,
  certificateDetails,
  selectedStandardIds,
  indicators,
  instrumentDetails
) => {
  try {
    let data = {
      calibrationDate: datasheetDetails?.calibrationDate
        ? moment(datasheetDetails?.calibrationDate).format("YYYY-MM-DD")
        : null,
      nextDueDate: datasheetDetails?.nextDueDate
        ? moment(datasheetDetails?.nextDueDate).format("YYYY-MM-DD")
        : null,
      receiptDate: datasheetDetails?.receiptDate
        ? moment(datasheetDetails?.receiptDate).format("YYYY-MM-DD")
        : null,
      DCDate: datasheetDetails?.dcDate
        ? moment(datasheetDetails?.dcDate).format("YYYY-MM-DD")
        : null,
      additionalStandardIds: selectedStandardIds?.join(","),
      identificationNo: datasheetDetails?.identificationNo,
      dateOfIssue: datasheetDetails?.dateOfIssue
        ? moment(datasheetDetails?.dateOfIssue).format("YYYY-MM-DD")
        : null,
      certificateNumber: certificateDetails?.certificateNumber,
      serviceReqNumber: certificateDetails?.serviceReqNumber,
      ULRNo: certificateDetails?.ULRNo,
      make: datasheetDetails?.make,
      ranges: datasheetDetails?.ranges,
      lc: datasheetDetails?.lc,
      DUCID: datasheetDetails?.DUCID,
      mfgNo: datasheetDetails?.mfgNo,
      startTemp: datasheetDetails?.startTemp,
      endTemp: datasheetDetails?.endTemp,
      startHumidity: datasheetDetails?.startHumidity,
      endHumidity: datasheetDetails?.endHumidity,
      calibrationReason: datasheetDetails?.calibrationReason,
      complianceStatus: indicators?.complianceStatus,
      tempDiff: String(
        Math.abs(
          Number(datasheetDetails?.startTemp) -
            Number(datasheetDetails?.endTemp)
        ).toFixed(2)
      ),
      humDiff: String(
        Math.abs(
          Number(datasheetDetails?.startHumidity) -
            Number(datasheetDetails?.endHumidity)
        ).toFixed(2)
      ),
      configuration: JSON.stringify({
        remark:
          datasheetDetails?.configuration?.remark ||
          datasheetDetails?.defaultReamrk ||
          instrumentDetails?.instrumentRemark,
      }),
      tableDisplayStatus: datasheetDetails.tableDisplayStatus,
      approvedby: null,
      reviewedBy: null,
      signedBy: null,
      rejectedBy: null,
      witnessBy: null,
      approvedDate: null,
      reviewedDate: null,
      witnessDate: null,
      signedDate: null,
    };

    let res = await axiosWithToken.patch(
      BASE_URL + `certificates/${datasheetId}`,
      data
    );
    if (res) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(
      `[ERROR] Failed to submit certificate details, error:  ${error}`
    );
    return false;
  }
};

export const submitSrfIntrument = async (datasheetId, datasheetDetails) => {
  let data = {
    make: datasheetDetails?.make,
    ranges: datasheetDetails?.ranges,
    lc: datasheetDetails?.lc,
    DUCID: datasheetDetails?.DUCID,
    serialNumber: datasheetDetails?.serialNo,
    model: datasheetDetails?.models,
  };

  try {
    let res = await axiosWithToken.patch(
      BASE_URL + `srfInstruments/${datasheetId}`,
      data
    );
    if (res) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error(
      `[ERROR] Failed to submit srf instrument details, error: ${err}`
    );
    return false;
  }
};

export const submitSRF = async (datasheetDetails, srfDetails) => {
  try {
    let row = {
      receiptDate: datasheetDetails?.receiptDate
        ? moment(datasheetDetails?.receiptDate).format("YYYY-MM-DD")
        : null,
      dcNumber: datasheetDetails?.dcNo,
      dcDate: datasheetDetails?.dcDate
        ? moment(datasheetDetails?.dcDate).format("YYYY-MM-DD")
        : null,
      // address: address,
      poNumber: datasheetDetails?.poNo,
      poDate: datasheetDetails?.poDate
        ? moment(datasheetDetails?.poDate).format("YYYY-MM-DD")
        : null,
    };
    let res = await axiosWithToken.patch(
      BASE_URL + `srfs/${srfDetails?.srfId}`,
      row
    );

    if (res) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error while updating createSRFAnalytics, error: ", error);
    return false;
  }
};

export const submitDatasheetReadings = async (
  datasheetId,
  datasheetDetails,
  datasheetStaticReadings,
  updatedStaticReadingRows,
  staticTables,
  selectedSupportiveRanges
) => {
  try {
    let user = [localStorage.getItem("id"), localStorage.getItem("userName")];
    let updatedReadingRows = updatedStaticReadingRows;

    // create list of reading objects
    let readings = [];
    let datasheetReadingIds = [];
    let indx = 0;
    let currentRowIndex = -1;

    updatedReadingRows.forEach((updatedReadingRow, idx) => {
      let row = {};
      row["tableId"] = updatedReadingRow[0];
      row["datasheetId"] = updatedReadingRow[1];
      let base = 2;

      let customColumnCount = getCustomColumnCount(
        updatedReadingRow[0],
        null,
        staticTables,
        "static"
      );
      if (String(updatedReadingRow[base + 1]).includes("_unit_"))
        currentRowIndex = idx;
      for (let j = 0; j < customColumnCount; j++) {
        row[`c${j + 1}`] = withUnit(
          updatedReadingRow[base + j] !== undefined ||
            updatedReadingRow[base + j] !== null
            ? updatedReadingRow[base + j]
            : null,
          currentRowIndex > -1
            ? updatedReadingRows[currentRowIndex][base + j]
            : ""
        );
      }

      for (let j = customColumnCount; j < MAX_COLS; j++) {
        row[`c${j + 1}`] = null;
      }

      // set uncertainty unit only
      if (
        String(updatedReadingRow[base + customColumnCount]).includes("_unit_")
      ) {
        row["uncertainty"] = updatedReadingRow[
          base + customColumnCount
        ]?.replaceAll("$", "");
      } else {
        row["uncertainty"] =
          updatedReadingRow[base + customColumnCount] !== undefined ||
          updatedReadingRow[base + customColumnCount] !== null
            ? updatedReadingRow[base + customColumnCount]
            : null;

        row["uncertainty"] = String(row["uncertainty"])?.replaceAll("$", "");
        row["uncertainty"] = withUnit(
          row["uncertainty"],
          currentRowIndex > -1
            ? updatedReadingRows[currentRowIndex][base + customColumnCount]
            : ""
        );
      }

      // set standard range ids
      row["standardRanges"] = updatedReadingRow[base + customColumnCount + 1]
        ? updatedReadingRow[base + customColumnCount + 1]
            ?.split(",")
            ?.filter((e) => e)
            ?.join(",")
        : updatedReadingRow[base + customColumnCount + 1];

      // resolve typeB values
      let table = staticTables.find((table) => table.id == row["tableId"]);
      let defaultConfiguration = JSON.parse(
        table?.defaultConfiguration || null
      );

      if (defaultConfiguration?.typeb?.relations) {
        row["typeBValues"] = prepareTypeBValues(
          defaultConfiguration.typeb?.relations,
          row
        );
      } else {
        row["typeBValues"] = null;
      }

      updatedReadingRow[updatedReadingRow.length - 1] != 0 &&
        datasheetReadingIds.push(
          updatedReadingRow[updatedReadingRow.length - 1]
        );

      row["supportiveRanges"] =
        selectedSupportiveRanges[indx] !== undefined &&
        selectedSupportiveRanges[indx] !== null
          ? selectedSupportiveRanges[indx]
          : null;
      indx += 1;
      readings.push(row);
    });

    let st = {};
    staticTables.map((e) => (st[e.id] = e));

    let changeLog = [];
    // TODO: (akshay) commented audit log code as we are auditing at database level
    // changeLog = [
    //   ...readings
    //     .map((e, i) => [
    //       i,
    //       [
    //         ...Object.entries(e)
    //           .map((r, j) =>
    //             datasheetStaticReadings[i] &&
    //             r[1] == datasheetStaticReadings[i][r[0]]
    //               ? true
    //               : `${r[0].startsWith("c") ? st[e.tableId][r[0]] : r[0]} : ${
    //                   datasheetStaticReadings[i]
    //                     ? datasheetStaticReadings[i][r[0]]
    //                     : ""
    //                 } >> ${r[1]}`
    //           )
    //           .filter((e2) => e2 !== true),
    //       ],
    //     ])
    //     .filter((e) => e[1].length)
    //     .map((e) => `${e[0]} : ${e[1].join(" , ")}`),
    // ]
    //   .join(" | ")
    //   .replaceAll("#", " ")
    //   .replaceAll("_rh_", "")
    //   .replaceAll("_unit_", "");

    // clear previous readings
    let status = true;
    if (datasheetReadingIds?.length > 0) {
      status = await clearPreviousReadings(
        "datasheetStaticReadings",
        "datasheetId",
        datasheetDetails?.id
      );
    }
    if (!status) return false;

    // update datasheet readings
    let datasheetProgress = 25;

    if (readings.length > 0) {
      let res = await axiosWithToken.post(
        BASE_URL + "datasheetStaticReadings/bulk",
        readings
      );
      if (res) {
        if (changeLog.length)
          await generateAudit(
            "datasheet",
            datasheetDetails?.jobNumber,
            user[0],
            moment().format("yyyy-MM-DD HH:mm:ss"),
            "Reading Updated",
            changeLog,
            datasheetDetails?.clientIdNumber
          );
        datasheetProgress = 60;
      }
    }

    // update analytics
    await updateSRFAnalytics([datasheetId], datasheetProgress);

    return [true, readings];
  } catch (err) {
    console.error(
      `[ERROR] Failed to submit datasheet readings (or associated function error), error: ${err}`
    );
    return false;
  }
};

export const submitCertificateReadings = async (
  datasheetDetails,
  certificateDetails,
  certificateStaticTables,
  certificateStaticReadings,
  updatedStaticReadingRows,
  dsReadings,
  staticTables,
  rowWiseComplianceStatus
) => {
  let user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  // sync automated tables with datasheet
  let _certificateStaticTables = [...certificateStaticTables];
  staticTables.forEach((table) => {
    if (
      !_certificateStaticTables.some((certTable) => certTable.id == table.id)
    ) {
      _certificateStaticTables.push(table);
    }
  });

  // prepare readings
  let config = _certificateStaticTables.map((table) => {
    let defaultConfiguration = JSON.parse(table.defaultConfiguration);
    return {
      tableId: table.id,
      formulas: defaultConfiguration.formulas,
      relations: defaultConfiguration.relations,
      totalColumns: defaultConfiguration.totalColumns,
    };
  });
  let updatedReadingRows = prepareCertificateReadings({
    datasheetReadings: updatedStaticReadingRows,
    config: config,
    precisionCount: 7,
  });

  // create json array of readings
  let readings = [];
  let certificateReadingIds = [];
  for (let i = 0; i < updatedReadingRows?.length; i++) {
    let row = {};
    row["tableId"] = updatedReadingRows[i][0];
    row["certificateId"] = Number(updatedReadingRows[i][1]);
    row["complianceStatus"] = rowWiseComplianceStatus[i] == false ? 0 : 1;
    let base = 2;

    let customColumnCount = getCustomColumnCount(
      updatedReadingRows[i][0],
      certificateStaticTables,
      null,
      "static",
      "certificate",
      _certificateStaticTables
    );

    for (let j = 0; j < customColumnCount; j++) {
      row[`c${j + 1}`] =
        updatedReadingRows[i][base + j] !== undefined ||
        updatedReadingRows[i][base + j] !== null
          ? updatedReadingRows[i][base + j]
          : null;
    }

    for (let j = customColumnCount; j < MAX_COLS; j++) {
      row[`c${j + 1}`] = null;
    }

    updatedReadingRows[i][updatedReadingRows[i].length - 1] != 0 &&
      certificateReadingIds.push(
        updatedReadingRows[i][updatedReadingRows[i].length - 1]
      );

    row["uncertainty"] = (dsReadings[i]["uncertainty"] || "").split("#")[0];
    // set standard range ids
    row["standardRanges"] = dsReadings[i]["standardRanges"]
      ? dsReadings[i]["standardRanges"]
          ?.split(",")
          ?.filter((e) => e)
          ?.join(",")
      : null;
    readings.push(row);
  }

  // clear previous readings
  certificateReadingIds = certificateStaticReadings.map((row) => row.id);
  // clear previous readings
  let status = true;
  if (certificateReadingIds?.length > 0)
    status = await clearPreviousReadings(
      "certificateStaticReadings",
      "certificateId",
      datasheetDetails?.id
    );
  let st = {};
  staticTables.map((e) => (st[e.id] = e));

  let changeLog = [];
  // TODO: (akshay) commented audit log code as we are auditing at database level
  // changeLog = [
  //   ...readings
  //     .map((e, i) => [
  //       i,
  //       [
  //         ...Object.entries(e)
  //           .map((r, j) =>
  //             certificateStaticReadings[i] &&
  //             r[1] == certificateStaticReadings[i][r[0]]
  //               ? true
  //               : `${r[0].startsWith("c") ? st[e.tableId][r[0]] : r[0]} : ${
  //                   certificateStaticReadings[i]
  //                     ? certificateStaticReadings[i][r[0]]
  //                     : ""
  //                 } >> ${r[1]}`
  //           )
  //           .filter((e2) => e2 !== true),
  //       ],
  //     ])
  //     .filter((e) => e[1].length)
  //     .map((e) => `${e[0]} : ${e[1].join(" , ")}`),
  // ]
  //   .join(" | ")
  //   .replaceAll("#", " ")
  //   .replaceAll("_rh_", "")
  //   .replaceAll("_unit_", "");
  if (status === true) {
    try {
      let res = await axiosWithToken.post(
        BASE_URL + "certificateStaticReadings/bulk",
        readings
      );

      if (res) {
        if (changeLog.length)
          await generateAudit(
            "certificate",
            certificateDetails?.certificateNumber,
            user[0],
            moment().format("yyyy-MM-DD HH:mm:ss"),
            "Reading Updated",
            changeLog,
            datasheetDetails?.clientIdNumber
          );
      }
      return true;
    } catch (error) {
      console.error(
        `[ERROR] Failed to submit certificate reading rows, error: ${error}`
      );
      return false;
    }
  } else {
    toast.error("Failed to save datasheet, please try again");
  }
};

export const submitDatasheetReadingsAdditionalInfo = async (
  DatasheetReadingsAdditionalInfo,
  datasheetId
) => {
  try {
    // Define the normalizeArray function
    function normalizeArray(array, keysToEnsure) {
      // Iterate over each object in the array
      array?.forEach((obj) => {
        // Iterate over the keys that should be present
        keysToEnsure?.forEach((key) => {
          // If the key is not present in the object, add it with a value of null
          if (!(key in obj)) {
            obj[key] = null;
          }
        });
      });
      return array;
    }

    // Find all keys present in DatasheetReadingsAdditionalInfo array
    const allKeys = DatasheetReadingsAdditionalInfo?.reduce((keys, obj) => {
      Object?.keys(obj)?.forEach((key) => {
        if (!keys?.includes(key)) {
          keys?.push(key);
        }
      });
      return keys;
    }, []);

    // Exclude any keys that are not needed (e.g., 'id', 'status', 'lastModified', etc.)
    const keysToEnsure = allKeys.filter(
      (key) => key !== "id" && key !== "status" && key !== "lastModified"
    );

    // Normalize the data
    const normalizedData = normalizeArray(
      DatasheetReadingsAdditionalInfo,
      keysToEnsure
    );

    // Delete existing entries if there are objects with id
    if (normalizedData?.length > 0) {
      const deleteQuery = {
        query: `DELETE FROM datasheetReadingsAdditionalInfo WHERE datasheetReadingId=${datasheetId}`,
      };
      await axiosWithToken.post(BASE_URL + `dynamic`, deleteQuery);
    }

    // Insert new entries without id
    if (normalizedData?.length > 0) {
      await axiosWithToken.post(
        BASE_URL + "datasheetReadingsAdditionalInfo/bulk",
        normalizedData
      );
      toast.success("Datasheet Readings Additional Info Added Successfully!");
    }

    return true;
  } catch (error) {
    console.error(
      `[ERROR] Failed to submit AdditionalInfo of Datasheet readings rows, error: ${error}`
    );
    return false;
  }
};

export const datasheetsApprove = (datasheetId) => {
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  axiosWithToken
    .patch(BASE_URL + `datasheets/${datasheetId}`, {
      datasheetApprovedBy: user[0],
    })
    .then((res) => {
      toast.success("Datasheet Approved successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
    .catch((err) => console.log(err));
};
