import { useEffect, useState } from "react";
import DownloadPdfLoaderModal from "./downloadPdfLoader";

const NetworkCheck = () => {
  const [isShow, setShow] = useState(false);
  const [network, setnetwork] = useState("fast");

  useEffect(() => {
    const handleNetworkStatus = (e) => {
      // console.log("network status", e);
      setnetwork(e.detail.status);
    };

    window.addEventListener("networkStatus", handleNetworkStatus);

    return () => {
      window.removeEventListener("networkStatus", handleNetworkStatus);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (network === "slow") {
      setShow(true);

      interval = setInterval(() => {
        const connection =
          navigator.connection ||
          navigator.mozConnection ||
          navigator.webkitConnection;
        if (connection.downlink > 0.9) {
          setShow(false);
          setnetwork("fast");
        }
      }, 1000); // Check every second
    } else {
      setShow(false);
    }

    return () => clearInterval(interval);
  }, [network]);

  return (
    <div>
      {isShow && (
        <DownloadPdfLoaderModal loading={isShow} name={"Network Slow"} network={isShow} />
      )}
    </div>
  );
};
export default NetworkCheck;