import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ClassicTable } from "../../../utils/components/Styles";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
export default function MaintenanceData() {
  const [problemIdentification, setProblemIdentification] = React.useState("");
  const [correctiveAction, setCorrectiveAction] = React.useState("");
  const [AgencyName, setAgencyName] = React.useState("");
  const [remarks, setRemarks] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [maintenanceArray, setMaintenanceArray] = React.useState([]);
  const [standardName, setStandardName] = React.useState("");
  const [executing, setExecuting] = React.useState(false);

  const params = useParams();

  const getMaintenanceList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `maintenance?_where=(standardId,eq,${parseInt(params.standardId)})`
      )
      .then((res) => {
        setMaintenanceArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getStandardNameList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `standards?_where=(id,eq,${parseInt(params.standardId)})`)
      .then((res) => {
        setStandardName(res.data[0].standardName);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const handleSubmit = (event) => {
    setExecuting(true);
    let row = {
      standardId: parseInt(params.standardId),
      date: date ? moment(date).format("YYYY-MM-DD") : null,
      problemIdentified: problemIdentification,
      correctiveAction: correctiveAction,
      agencyName: AgencyName,
      remarks: remarks,
    };
    let url = BASE_URL;
    axiosWithToken
      .post(url + "maintenance", row)
      .then((res) => {
        toast("Maintenance Save successfully!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    getMaintenanceList();
    getStandardNameList();
  });

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h5" component="h5" style={{ float: "center" }}>
        <b>
          <u>{standardName}</u>
        </b>
      </Typography>
      <br />
      <br />
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              InputLabelProps={{ shrink: true }}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={date ? date : new Date()}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Agency Name"
            size="small"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setAgencyName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Problem Identification"
            multiline
            rows={3}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={(e) => {
              setProblemIdentification(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{ shrink: true }}
            label="Corrective Action"
            size="small"
            multiline
            rows={3}
            onChange={(e) => {
              setCorrectiveAction(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Remarks"
            size="small"
            multiline
            rows={3}
            fullWidth
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            disabled={executing}
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <br />
      <div>
        <Typography variant="h6" component="div" sx={{ mb: 2 }}>
          Maintenance Records
        </Typography>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Problem Identification</TableCell>
                <TableCell>Corrective Action</TableCell>
                <TableCell>Agency Name</TableCell>
                <TableCell>Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {maintenanceArray.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {row.date ? moment(row.date).format("DD-MM-YYYY") : ""}
                  </TableCell>
                  <TableCell>{row.problemIdentified}</TableCell>
                  <TableCell>{row.correctiveAction}</TableCell>
                  <TableCell>{row.agencyName}</TableCell>
                  <TableCell>{row.remarks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />

      <ToastContainer />
    </Paper>
  );
}
