import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, TextField, Typography } from "@mui/material";
import { ClassicTable } from "../../../utils/components/Styles";

const SrfRowDetailsModal = ({
  open,
  handleClose,
  rowIndex,
  row,
  fixedColumns,
  updateCellValue,
  setMultiValueData,
  setMultiValueModalOpen,
  instrumentList,
  getInstrument,
  set_DUIC_ID,
  updateSrfDetails,
  setUnitFilter,
  excelImport,
  settings,
  DUC_ID,
  checkDucMasterRecord,
  checkLatestInstrumentRecord,
  additionalReadingColumns,
  renderSRFRow,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 380,
          "@media (max-width: 600px)": {
            width: "80%", 
          },
          "@media (max-width: 480px)": {
            width: "90%", 
          },
          backgroundColor: "white",
          height: "90vh",
          overflow: "scroll",
          // border: "2px solid #000",
          boxShadow: 24,
          padding: "10px",
        }}
      >
        <TableContainer component={Paper}>
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right", marginBottom: "15px" }}
            onClick={handleClose}
          >
            X
          </Button>
          <ClassicTable>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {fixedColumns?.map((ele) => (
                  <TableCell style={{ minWidth: "140px", minHeight: "58px" }}>
                    {ele?.headerName}
                  </TableCell>
                ))}
                {additionalReadingColumns?.map((ele) => (
                  <TableCell style={{ minWidth: "140px", minHeight: "58px" }}>
                    {ele?.headerName}
                  </TableCell>
                ))}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {renderSRFRow(
                  rowIndex,
                  row,
                  fixedColumns,
                  updateCellValue,
                  setMultiValueData,
                  setMultiValueModalOpen,
                  instrumentList,
                  getInstrument,
                  set_DUIC_ID,
                  updateSrfDetails,
                  setUnitFilter,
                  excelImport,
                  settings,
                  DUC_ID,
                  checkDucMasterRecord,
                  checkLatestInstrumentRecord
                )}
              </div>
            </div>
          </ClassicTable>
        </TableContainer>
      </div>
    </Modal>
  );
};

export default SrfRowDetailsModal;
