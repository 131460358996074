import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";

const ExcelDownload = ({ finalData, notNeededColumn }) => {
  const convertToExcel = () => {
    const dataWithoutNotNeededKeys =
      notNeededColumn.length > 0
        ? finalData.map((item) => {
            const filteredItem = {};
            for (const key in item) {
              if (item.hasOwnProperty(key) && !notNeededColumn.includes(key)) {
                filteredItem[key] = shouldConvertToDate(key)
                  ? item[key] ? new Date(item[key]).toLocaleString() : ""
                  : item[key];
              }
            }
            return filteredItem;
          })
        : finalData;

    const worksheet = XLSX.utils.json_to_sheet(dataWithoutNotNeededKeys);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "data.xlsx");
  };

  function shouldConvertToDate(key) {
    // Add keys that should be converted to a localized string here
    const dateKeys = ["checkinDatetime", "checkoutDatetime", "lastModified","outwardDate","expectedReturnDate","returnDate","siteInwardDate"];
    return dateKeys.includes(key);
  }

  return (
    <div>
      <Button
        id="stdMastersEQPList_convertexcel"
        variant="contained"
        size="small"
        style={{ marginLeft: "10px", height: "40px", fontSize: "12px" }}
        onClick={convertToExcel}
      >
        Export Excel
      </Button>
    </div>
  );
};

export default ExcelDownload;
