import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import PreviewIcon from "@mui/icons-material/Preview";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
  Modal,
  Box,
  useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

var columns = [
  {
    id: "label",
    label: "Remark Label",
    align: "left",
    minWidth: 40,
  },

  {
    id: "value",
    label: "Remark Description",
    align: "left",
    minWidth: 40,
  },
];

const RemarkList = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const urlPath = useLocation();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Remark Label");
 const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [searched, setSearched] = React.useState(false);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Remark Label": "label",
        "Remark Description": "value",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT *  FROM remarks  ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM remarks ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("Remarks data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("Remarks data fetching error: ", err);
      });
  }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    fetchFinalData();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Remark Label");
    setSearchKey("");
    setSearched(false); 
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `remarks/${id}`)
      .then((res) => {
        toast("Instrument_status is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };


  useEffect(() => {
    if (searched==false) {
    fetchFinalData();
    getTotalRows();
    }
  }, [rowsPerPage, page,searched]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit Instrument Status",
      link: true,
      linkTo: (id) => `/master/editRemark/${id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete Instrument Status",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (id) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(id),
    },
  ];

  const filterSection = () => (
    <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <div
            className="mb-2"
            style={{
              display: "flex",
              float: "left",
              gap: "5px",
              flexWrap:"wrap"
            }}
          >
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
              }}
              size="small"
              id="combo-box-demo"
              value={searchBy}
              options={
                _userType != 3
                  ? [
                      { key: "label", label: "Remark Label" },
                      { key: "value", label: "Remark Description" },
                    ]
                  : [{ key: "label", label: "Remark Label" }]
              }
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                setSearched(false);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value)
                setSearched(false);
              }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                searched ? clearSearch() : search();
                handleFilterClose();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
          </div>
        </div>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
      {filterModal()}
        <Box sx={{ display: "flex", gap: "1rem",justifyContent:"space-between" }}>
        <Toolbar>
          {showComponent("add") && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/master/editRemark"
            >
              <b>ADD NEW REMARK</b>
            </Button>
          )}
        </Toolbar>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
        </Box>
        {!isMobile && filterSection()}
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
};

export default RemarkList;
