import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
export default function CheckRecords() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  var [standardData, setStandardData] = React.useState([]);
  var [intermediateProcedureList, setIntermediateProcedure] = React.useState(
    []
  );
  var [standards, setStandards] = React.useState("");
  var [standardIds, setStandardIds] = React.useState("");
  const [checkPoint, setCheckPoint] = React.useState("");
  const [observation, setObservation] = React.useState("");
  const [remarks, setRemarks] = React.useState("");
  const [stId, setId] = React.useState("");
  const [date, setDate] = React.useState("");
  const [checkList, setCheckList] = React.useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 40 },
    { id: "date", label: "Date", minWidth: 40 },
    {
      id: "checkPoint",
      label: "Check Point",
      minWidth: 40,
      align: "left",
    },
    {
      id: "observation",
      label: "Observation",
      align: "left",
      minWidth: 40,
    },
    {
      id: "remarks",
      label: "Remarks",
      minWidth: 40,
    },
  ];

  const onSubmit = () => {
    var jsonData = {
      standardId: parseInt(standardIds.split(",")[0]),
      checkPoint: checkPoint,
      observation: observation,
      remarks: remarks,
      date: date ? moment(date).format("YYYY-MM-DD") : null,
    };

    let url = BASE_URL;
    axiosWithToken
      .post(url + "IntermediateCheckRecords", jsonData)
      .then((res) => {
        toast("new Intermediate Procedure added!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const getStandards = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "standards?_where=(status,eq,1)")
      .then((res) => {
        setStandardData(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const getIntermediateProcedure = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "intermediateProcedure?_where=(status,eq,1)")
      .then((res) => {
        setIntermediateProcedure(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/standard">Home</Link>
  };

  const initiateStandardData = () => {
    var pushCArray = [];
    for (let i = 0; i < standardData.length; i++) {
      if (standardData[i].standardName) {
        pushCArray.push({
          label: standardData[i].id + ", " + standardData[i].standardName,
        });
      }
    }
    setStandards(pushCArray);
  };

  const getCheckList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "IntermediateCheckRecords")
      .then((res) => {
        setCheckList(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  useEffect(() => {
    initiateStandardData();
  }, [standardData]);

  useEffect(() => {
    getCheckList();
    getIntermediateProcedure();
    getStandards();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create new Intermediate Procedure
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={standards}
            renderInput={(params) => (
              <TextField {...params} label="standards" />
            )}
            onInputChange={(event, newInputValue) => {
              setStandardIds(newInputValue);
              intermediateProcedureList.forEach((data, i) => {
                data.standardIds.split(",").forEach((data1, i) => {
                  if (parseInt(data1) == parseInt(newInputValue[0])) {
                    setCheckPoint(data.checkPoint);
                  }
                  standardData.forEach((data2, i) => {
                    if (parseInt(data2.id) == parseInt(newInputValue[0])) {
                      setId(data2.stId);
                    }
                  });
                });
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Id No"
            size="small"
            fullWidth
            value={stId}
            disabled
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setObservation(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={date ? date : new Date()}
              onChange={(e) => setDate(e)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Observation"
            size="small"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setObservation(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Remarks"
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Check Point"
            rows={3}
            InputLabelProps={{ shrink: true }}
            multiline
            value={checkPoint}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setCheckPoint(e.target.value)}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            onSubmit();
          }}
        >
          Save Intermediate Procedure
        </Button>
      </Toolbar>
      <ToastContainer />
      <br />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {checkList &&
              checkList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {row.date ? moment(row.date).format("DD-MM-YYYY") : ""}
                      </TableCell>
                      <TableCell>{row.checkPoint}</TableCell>
                      <TableCell>{row.observation}</TableCell>
                      <TableCell>{row.remarks}</TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={checkList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </TableContainer>
      <br />
    </Paper>
  );
}
