import axios from "axios";
import { BASE_URL } from "../../global";

// networkCheckTables
let netWorkTableCheck = ["standards", "standardRanges","instruments","cmc","srfs","srfInstruments","datasheets","certificates","csrfForm","datasheetStaticReadings","certificateStaticReadings","datasheetReadingsAdditionalInfo"];

// Create axios instance directly
const axiosWithToken = axios.create({
  baseURL: BASE_URL,
});

// Add an interceptor to include the token in the headers
axiosWithToken.interceptors.request.use((config) => {
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;

  if (
    (config.method == "patch" || config.method == "post") &&
    netWorkTableCheck?.some((tables) => config?.url?.includes(tables)) &&
    connection.downlink < 0.9
  ) {
    const event = new CustomEvent("networkStatus", {
      detail: { status: "slow" },
    });
    window.dispatchEvent(event);
    return Promise.reject(new Error("request_aborted"));
  } else {
    const token = sessionStorage.getItem("authToken"); // Get the token from session storage
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`; // Include the token in the headers
    }
    return config;
  }
});

// Export axiosWithToken directly
export default axiosWithToken;
