import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Chip,
  Divider,
  FormControlLabel,
  Checkbox,
  Modal,
  Box,
  List,
  ListItemText,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CButton from "../../../utils/components/Button";
import { ListItem } from "@material-ui/core";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";



export default function Template(props) {
  const [templateConfig, setTemplateConfig] = useState([]);

  var [instrumentArray, setInstrumentArray] = useState([]);
  const [instrumentId, setInstrumentId] = useState(null);
  const [instrumentName, setInstrumentName] = useState(null);
  const [tables, setTables] = useState([]);

  // destructuring rgba from state
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const params = useParams();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const onSubmit = () => {
    let jsonData = {
      instrumentId: instrumentId,
      instrumentName: instrumentName,
      config: JSON.stringify(templateConfig),
    };
    let url = BASE_URL;
    if (params.id > 0) {
      axiosWithToken
        .patch(url + `datasheetTemplate/${params.id}`, jsonData)
        .then((res) => {
          toast.success("Template updated successfully");
        })
        .catch((error) => {
          console.log(error);
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong error code: 1 !");
          }
        });
    } else {
      axiosWithToken
        .post(url + "datasheetTemplate", jsonData)
        .then((res) => {
          toast.success("Template created successfully");
        })
        .catch((error) => {
          console.log(error);
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong error code: 2 !");
          }
        });
    }
    setTimeout(() => {
      props.setLoader(false);
    }, 500);
  };

  const addStep = () => {
    setTemplateConfig([
      ...templateConfig,
      { instructions: [""], outputStream: "" },
    ]);
  };
  const addInstruction = (stepIndex) => {
    let _templateConfig = templateConfig;
    _templateConfig[stepIndex]["instructions"].push("");
    setTemplateConfig([..._templateConfig]);
  };

  const updateInstructionValue = (stepIndex, instructionIndex, value) => {
    let _templateConfig = templateConfig;
    _templateConfig[stepIndex]["instructions"][instructionIndex] = value;
    setTemplateConfig([..._templateConfig]);
  };

  const updateOoutputStreamValue = (stepIndex, value) => {
    let _templateConfig = templateConfig;
    _templateConfig[stepIndex]["outputStream"] = value;
    setTemplateConfig([..._templateConfig]);
  };

  const getDatasheetTemplate = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `datasheetTemplate?_where=(id,eq,${params.id})`)
      .then((res) => {
        if (res.data.length > 0) {
          setTemplateConfig(
            res.data[0]?.config ? JSON.parse(res.data[0]?.config) : []
          );
          setInstrumentId(Number(res.data[0].instrumentId));
        }
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong error code: 4 !");
        }
        console.log(error);
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong error code: 5 !");
        }
      });
  };

  useEffect(() => {
    getInstrumentsList();
    if (params.id) getDatasheetTemplate();
  }, []);

  useEffect(() => {
    if (instrumentArray !== null && instrumentId !== null) {
      for (let i = 0; i < instrumentArray.length; i++) {
        if (instrumentArray[i].id == instrumentId) {
          setInstrumentName(instrumentArray[i].instrumentName);
          break;
        }
      }
    }
  }, [instrumentArray, instrumentId]);

  const renderHelpModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Formula Help
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            same as mathematical expression with following variations
            <hr />
            <ul>
              <li>
                1. multiplication: <code>*</code>
              </li>
              <li>
                2. for power:{" "}
                <code>pow(&lt;base value&gt;, &lt;exponent&gt;)</code>
              </li>
              <li>
                3. for square root : <code>sqrt(&lt;value&gt;)</code>, value:
                either number of column code (eg. c1)
              </li>
            </ul>
          </Typography>
        </Box>
      </Modal>
    );
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
        {params.id > 0 ? "Update" : "Create new"} datasheet template
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={instrumentId + ", " + instrumentName}
            options={instrumentArray.map((instrument) => {
              return {
                label: instrument.id + ", " + instrument.instrumentName,
              };
            })}
            onChange={(event, value) => {
              if (value) {
                setInstrumentId(parseInt(value.label.split(",")[0]));
                setInstrumentName(value.label.split(",")[1]);
                // fetchUncertaintyFactors(value.label.split(",")[0]);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Instrument" />
            )}
          />
        </Grid>
      </Grid>

      {/* datasheet table confiurations */}
      <div>
        <Divider sx={{ mt: 5 }}>
          <Chip
            label="Datasheet table configuration"
            variant="outlined"
            sx={{ fontSize: "16px" }}
          />
        </Divider>
      </div>
      <div sx={{ mb: 3 }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          1. add steps
          <CButton
            variant="contained"
            size="small"
            label={<AddIcon />}
            sx={{ ml: 3 }}
            onClick={() => {
              addStep();
            }}
          ></CButton>
        </Typography>
        <Grid container spacing={1}>
          {(templateConfig || []).map((step, stepIndex) => {
            return (
              <Grid item xs={12}>
                <List
                  dense
                  sx={{
                    borderRadius: 1,
                    border: 1,
                    borderColor: "grey.600",
                    "&:hover": { borderColor: "grey.200" },
                  }}
                >
                  <ListItem>
                    <ListItemText primary={`step #${stepIndex + 1}`} />
                    <CButton
                      variant="contained"
                      size="small"
                      label={
                        <span>
                          instructions <AddIcon />
                        </span>
                      }
                      sx={{ ml: 3 }}
                      onClick={() => {
                        addInstruction(stepIndex);
                      }}
                    ></CButton>
                  </ListItem>
                  <Grid container spacing={1} margin={1}>
                    {step.instructions.map((instruction, instructionIndex) => {
                      return (
                        <Grid item xs={11}>
                          <TextField
                            id="outlined-basic"
                            label="Add instruction"
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={instruction}
                            onChange={(e) =>
                              updateInstructionValue(
                                stepIndex,
                                instructionIndex,
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                      );
                    })}

                    <Grid item xs={11} marginTop={4}>
                      Output stream:
                      <FormControlLabel
                        classes={{
                          alignItems: "left",
                        }}
                        style={{ display: "flex" }}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                updateOoutputStreamValue(stepIndex, "pass");
                              }
                            }}
                          />
                        }
                        label="pass to next step"
                        checked={step.outputStream === "pass" ? true : false}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Add destination mapping"
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={step.outputStream}
                        onInput={(e) =>
                          updateOoutputStreamValue(stepIndex, e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </List>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <CButton
          variant="contained"
          label="SAVE"
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          SAVE
        </CButton>
      </Toolbar>
      <ToastContainer />
      {renderHelpModal()}
    </Paper>
  );
}
