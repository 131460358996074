import React from "react";
import { Box, Tooltip } from "@mui/material";


import {
  moduleList,
  rolesArray,
  userDesignation,
  userTypes,
} from "../master/userMaster/editUserMaster";

const DesignationTable = ({ tableName, tableimport }) => {
  if (!Array.isArray(tableimport)) {
    console.error(
      `Expected an array for tableimport, got: ${typeof tableimport}`
    );
    return null; 
  }

  return (
    <div>
      <h5 style={{ textAlign: "left" }}>
        <b>{tableName}</b>
      </h5>
      <br />
      <table>
        <thead>
          <tr>
            <th style={{ padding: "3px" }}>ID</th>
            <th style={{ padding: "3px" }}>Name</th>
          </tr>
        </thead>
        <tbody>
          {tableimport.map((item) => (
            <tr key={item.id}>
              <td style={{ padding: "3px" }}>{item.id}</td>
              <td style={{ padding: "3px", textAlign: "left" }}>
                {item?.name}
                {item?.label}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AppInfo = () => {
  return (
    <Tooltip sx={{ padding: "1rem" }}>
      <Box
        sx={{
          margin: "1rem",
          padding: "1.5rem 1rem",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "0.5rem",
        }}
      >
        <DesignationTable tableName={"User Types"} tableimport={userTypes} />
        <DesignationTable tableName={"Roles"} tableimport={rolesArray} />
        <DesignationTable
          tableName={"User Designation"}
          tableimport={userDesignation}
        />
        <DesignationTable tableName={"Modules"} tableimport={moduleList} />
      </Box>
    </Tooltip>
  );
};

export default AppInfo;
