import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from "react-toastify";
import { showComponent } from "../../components/helper/helpers";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../global";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";

const StatusArray = [
  { id: 1, name: "Missing" },
  { id: 2, name: "Scrapped" },
];

const Tables = (props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const handleChangePage = (event, newPage) => {
    props?.setPage(newPage);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleChangeRowsPerPage = (event) => {
    props?.setRowsPerPage(+event.target.value);
    props?.setPage(0);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  let showAction = props?.actions
    ?.map((e) => showComponent(e.id))
    ?.filter((e) => e)?.length;

  return (
    <div>
    <div 
  style={props?.isPrint ? { border: "1px solid black", padding: "5px" } : { border: "none" }} 
  ref={props?.printComponentRef} 
>

     <TableContainer 

  sx={{ 
    maxHeight: props?.isPrint ? "none" : (isMobile ? "75vh" : "65vh"),
    // width: "95%",
    margin: "auto"
  }} 
>
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        // id="ducMaster_list_table"
        width="100%"
      >
        <TableHead>
          <TableRow >
            {props?.columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth}}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            {showAction ? (
              <TableCell>
                <b>Actions</b>
              </TableCell>
            ) : (
              ""
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.finalData &&
            props?.finalData.map((row, index) => {
              return (
                <TableRow hover id="pagbreaksborder" role="checkbox" tabIndex={-1} key={row.id}>
                  {props?.columns.map((column) => {
                    // let value = row[column.id];
                    let value;
                    if (
                      column.id == "date" ||
                      column.id == "calibrationDate" ||
                      column.id == "dueDate" ||
                      column.id == "expectedReturnDate" ||
                      column.id == "returnDate" 
                    ) {
                      let newDate = moment(row[column.id]).format("DD-MM-YYYY");
                      value = row[column.id] ? newDate : null;
                    } else {
                      value = row[column.id];
                    }
                    if (column.id == "id") {
                      value = props?.page * props?.rowsPerPage + (index + 1);
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  {showAction ? (
                    <TableCell>
                      <ButtonGroup
                        size="small"
                        aria-label="small button group"
                        className="action-container"
                      >
                        {props?.actions.map((action) => {
                          return (
                            showComponent(action.id) && (
                              <Tooltip
                                key={action.id}
                                title={action.tooltip}
                                placement="top-start"
                              >
                                {action.link ? (
                                  <Button
                                  id={`ducMaster_list_${action.id}`}
                                    component={Link}
                                    to={action.linkTo(row.id)}
                                  >
                                    {action.icon}
                                  </Button>
                                ) : (
                                  <Button
                                  id={`ducMaster_list_${action.id}`}
                                    onClick={() => action.handler(row.id)}
                                  >
                                    {action.icon}
                                  </Button>
                                )}
                              </Tooltip>
                            )
                          );
                        })}
                      </ButtonGroup>
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={props?.totalRows}
        rowsPerPage={props?.rowsPerPage}
        page={props?.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </div>
  );
};

export default Tables;
