import React, { useEffect } from "react";
import { BASE_URL } from "../../../src/global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { ClassicTable } from "../../utils/components/Styles";
import moment from "moment";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export default function CreateChallan() {
  const [srfObject, createSrfObject] = React.useState({});
  var [clientArray, setClientArray] = React.useState([]);
  const [address, setAddress] = React.useState("");
  const [totalQt, setTotalQt] = React.useState(0);
  const [readingRows, setReadingRows] = React.useState([
    ["", "", "", "", "", ""],
  ]);
  const [pushCArray, setPushCArray] = React.useState([]);
  const [clientsArrays, setClientsArrays] = React.useState([]);

  const getClientIdList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const generatePushCArrayAndClientsArrays = (clientArray) => {
    const newPushCArray = [];
    const newClientsArrays = [];

    for (let i = 0; i < clientArray.length; i++) {
      if (clientArray[i].companyName) {
        newPushCArray.push({
          label: clientArray[i].id + ", " + clientArray[i].companyName,
        });
      }

      newClientsArrays.push({
        id: clientArray[i].id,
        address: clientArray[i].address,
      });
    }

    setPushCArray(newPushCArray);
    setClientsArrays(newClientsArrays);
  };

  const counterAmt = () => {
    var count = 0;
    for (let i = 0; i < readingRows.length; i++) {
      if (readingRows[i][4] != "") {
        count += parseInt(readingRows[i][4]);
      }
    }
    setTotalQt(count);
  };

  useEffect(() => {
    generatePushCArrayAndClientsArrays(clientArray);
  }, [clientArray]);

  useEffect(() => {
    counterAmt();
  }, [readingRows]);

  useEffect(() => {
    getClientIdList();
  }, []);

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "discription",
        headerName: "Discription",
        editable: true,
      },
      {
        field: "size",
        headerName: "Size",
        editable: true,
      },
      {
        field: "slNo",
        headerName: "Sl No.",
        editable: true,
      },
      {
        field: "idNo",
        headerName: "Id No.",
        editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "remarks",
        headerName: "Remarks",
        editable: true,
      },
      {
        field: "Action",
      },
    ],
  ];

  // submit form
  const submitDescriptionRows = (readingRows, id) => {
    let rows = readingRows.map((row) => ({
      challanId: id,
      discription: row[0],
      size: row[1],
      slNo: row[2],
      idNo: row[3],
      quantity: row[4],
      remarks: row[5],
    }));

    rows.map((rows) => {
      let url = BASE_URL;
      axiosWithToken
        .post(url + "challanReadings", rows)
        .then((res) => {
          // toast("Challan created successfully !");
          // setTimeout(refresh, 500);
        })
        .catch((error) => {
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      clientId: srfObject.clientId,
      challanNo: srfObject.challanNo,
      challanDate: srfObject.challanDate
        ? moment(srfObject.challanDate).format("YYYY-MM-DD")
        : null,
      dcNumber: srfObject.dcNumber,
      dcDate: srfObject.dcDate
        ? moment(srfObject.dcDate).format("YYYY-MM-DD")
        : null,
    };

    let url = BASE_URL;

    axiosWithToken
      .post(url + "challanMaster", row)
      .then((res) => {
        toast("Challan created successfully !");
        var insertedID = res.data.insertId;
        submitDescriptionRows(readingRows, insertedID);
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  //ToDo: fetch cutomers from database

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };
  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 0);
    setReadingRows([...newReadingRows]);
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/standard">Home</Link>
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Challan
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => <TextField {...params} label="Client" />}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "clientId",
                parseInt(newInputValue.split(",")[0])
              );
              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue.split(",")[0])) {
                  if (client.address) {
                    setAddress(client.address);
                  } else {
                    setAddress("");
                  }
                }
              });
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Challan No."
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("challanNo", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Challan Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.challanDate ? srfObject.challanDate : new Date()}
              onChange={(newValue) => {
                updateSrfObject("challanDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{ shrink: true }}
            label="Address"
            value={address}
            size="small"
            disabled
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="DC Number"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("dcNumber", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="DC Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.dcDate ? srfObject.dcDate : new Date()}
              onChange={(newValue) => {
                updateSrfObject("dcDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>Products</h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    return (
                      <TableCell>
                        <TextField
                          size="small"
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                        ></TextField>
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3} justify="flex-end">
          <TextField
            id="outlined-basic"
            label="Total Quantity"
            size="small"
            fullWidth
            // value={gstType==1?totalAmount - (18 * totalAmount/100):totalAmount-(9 * totalAmount/100)}
            value={totalQt}
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
            //  onChange={(e) => {
            //   setTotalDiscount(e.target.value * totalAmount/100);
            // }}
          />
        </Grid>
      </Grid>
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
